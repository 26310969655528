export const SET_RTP = 'rtps/setData';
export const SET_GAME_JAM = 'jamGacor/setData';
export const SET_RTP_ISERROR = 'rtps/isError';
export const SET_RTP_ISLOADING = 'rtps/isLoading';

// Pragmatic
export const SET_PP_GAME_NAMES = 'names/setData';
export const SET_PP_GAME_IMAGES = 'images/setData';
export const SET_PP_GAME_POLA = 'ppPola/setData';
// PG-Soft
export const SET_PGS_GAME_NAMES = 'names/setData';
export const SET_PGS_GAME_IMAGES = 'images/setData';
export const SET_PGS_GAME_POLA = 'pgsPola/setData';
// Habanero
export const SET_HB_GAME_NAMES = 'names/setData';
export const SET_HB_GAME_IMAGES = 'images/setData';
export const SET_HB_GAME_POLA = 'hbPola/setData';
// Joker-Gaming
export const SET_JG_GAME_NAMES = 'names/setData';
export const SET_JG_GAME_IMAGES = 'images/setData';
export const SET_JG_GAME_POLA = 'jgPola/setData';
// Spade-Gaming
export const SET_SG_GAME_NAMES = 'names/setData';
export const SET_SG_GAME_IMAGES = 'images/setData';
export const SET_SG_GAME_POLA = 'sgPola/setData';
// Jili
export const SET_JILI_GAME_NAMES = 'names/setData';
export const SET_JILI_GAME_IMAGES = 'images/setData';
export const SET_JILI_GAME_POLA = 'jiliPola/setData';
// Fast-Spin
export const SET_PS_GAME_NAMES = 'names/setData';
export const SET_PS_GAME_IMAGES = 'images/setData';
export const SET_PS_GAME_POLA = 'psPola/setData';
// Play-Star
export const SET_FS_GAME_NAMES = 'names/setData';
export const SET_FS_GAME_IMAGES = 'images/setData';
export const SET_FS_GAME_POLA = 'fsPola/setData';
// Live22
export const SET_L22_GAME_NAMES = 'names/setData';
export const SET_L22_GAME_IMAGES = 'images/setData';
export const SET_L22_GAME_POLA = 'l22Pola/setData';
// AIS-Gaming
export const SET_AIS_GAME_NAMES = 'names/setData';
export const SET_AIS_GAME_IMAGES = 'images/setData';
export const SET_AIS_GAME_POLA = 'aisPola/setData';
// CQ9
export const SET_CQ9_GAME_NAMES = 'names/setData';
export const SET_CQ9_GAME_IMAGES = 'images/setData';
export const SET_CQ9_GAME_POLA = 'cq9Pola/setData';
// Micro-Gaming
export const SET_MG_GAME_NAMES = 'names/setData';
export const SET_MG_GAME_IMAGES = 'images/setData';
export const SET_MG_GAME_POLA = 'mgPola/setData';
// Top-Trend-Gaming
export const SET_TTG_GAME_NAMES = 'names/setData';
export const SET_TTG_GAME_IMAGES = 'images/setData';
export const SET_TTG_GAME_POLA = 'ttgPola/setData';
// General
export const SET_GAME_ISERROR = 'games/isError';
export const SET_GAME_ISLOADING = 'games/isLoading';