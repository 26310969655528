import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPGSGamesData, fetchPgPolasData } from '../../store/actions/pgsGameAction';
import Loading from '../Loading';

const PGSoftCard = (data) => {
  const dispatch = useDispatch();
  const { pgsNames, pgsImages, pgsPolas, nameAndImagesIsLoading } = useSelector((state) => state.pgsGameReducer);

  useEffect(() => {
    dispatch(fetchPgPolasData());
    dispatch(fetchPGSGamesData());
  }, [dispatch]);

  if (data.isLoading || nameAndImagesIsLoading) return (
    <>
      <Loading />
    </>
  )

  return (
    <>
      <h2>PGSOFT</h2>
      <section className="container px-3 sm:px-12 mx-auto" id="pgs">
        <div className='grid grid-cols-2 lg:grid-cols-6 gap-4 justify-items-center'>
          {
            pgsNames.map((pgsName, i) => {
              return (
                <div key={i} className="w-full grid justify-items-center bg-item p-3">
                  <div className="relative flex-shrink-0">
                    <img width={"150px"} src={pgsImages[i]} alt={pgsName} />
                  </div>
                  <div className="grid box-content text-center content-center capitalize mb-3">
                    <span className="game-name h-12 grid content-center">{pgsName}</span>
                    <div className="jam px-5 py-3 mb-3">
                      <span>Jam Gacor: </span>
                      <span className="jamGacor">
                        {data.jamGacors[i] ? data.jamGacors[i].value2 : ''}
                      </span>
                    </div>
                    <span>Pola: </span>
                    <span className="v">{pgsPolas[i] ? `${pgsPolas[i].value1} - ${pgsPolas[i].value2} - ${pgsPolas[i].value3}` : ''}</span>
                  </div>
                  <div className='single-bar relative items-center'>
                    <div className='absolute font-bold rtpValue' style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                      <span>{data.rtps[i] ? data.rtps[i].value2 : ''}%</span>
                    </div>
                    <div className="single-bar__fill bar animated-gradient" style={{ width: `${data.rtps[i] ? data.rtps[i].value2 : ''}%` }}></div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </section >
    </>
  );
};

export default PGSoftCard;