import {
  SET_PP_GAME_NAMES,
  SET_PP_GAME_IMAGES,
  SET_PP_GAME_POLA,
  SET_GAME_ISLOADING,
  SET_GAME_ISERROR
} from '../actionTypes';
import { mainAxios } from '../../apis/axios';

const cheerio = require("cheerio");
const now = new Date();
const pragmaticPola = ["✅", "❌", "✅", "❌", "✅", "❌", "✅", "❌"];
const betForPola = ["10", "20", "30", "50", "70", "100", "500", "1000"];

export const setPPGameNames = (payload) => {
  return {
    type: SET_PP_GAME_NAMES,
    payload: payload
  };
};

export const setPPGameImages = (payload) => {
  return {
    type: SET_PP_GAME_IMAGES,
    payload: payload
  };
};

export const setPragmaticPolas = (payload) => {
  return {
    type: SET_PP_GAME_POLA,
    payload: payload
  };
};

export const setGameIsLoading = (payload) => {
  return {
    type: SET_GAME_ISLOADING,
    payload: payload
  };
};

export const setGameIsError = (payload) => {
  return {
    type: SET_GAME_ISERROR,
    payload: payload
  };
};

export const fetchPPGamesData = () => {
  return async (dispatch) => {
    await mainAxios(
      {
        method: 'GET',
        url: '/api/?content=slot&provider=pp'
      }
    )
      .then(({ data }) => {
        const $ = cheerio.load(data);
        const check = $('.gameitem');
        let tempName = [];
        let tempImg = [];
        for (let i = 0; i < check.length; i++) {
          let nameGame = $(check[i]).find('figcaption').text();
          let imageGame = $(check[i]).find('img').attr('src');
          tempName.push(nameGame);
          tempImg.push(imageGame);
        }
        dispatch(setPPGameNames(tempName));
        dispatch(setPPGameImages(tempImg));
      })
      .catch(err => {
        dispatch(setGameIsError(err));
      })
      .finally(() => {
        dispatch(setGameIsLoading(false));
      });
  };
};

export const fetchPragmaticPolasData = () => {
  return async (dispatch) => {
    dispatch(setGameIsLoading(true));
    await mainAxios(
      {
        method: 'GET',
        url: '/getPragmaticPolas'
      }
    )
      .then(({ data }) => {
        for (let i = 0; i < 1000; i++) {
          let pola11 = pragmaticPola[Math.floor(Math.random() * 8)];
          let pola12 = "";
          pola11 === "✅" ? pola12 = "❌" : pola12 = "✅";
          let pola21 = pragmaticPola[Math.floor(Math.random() * 8)];
          let pola22 = "";
          pola21 === "✅" ? pola22 = "❌" : pola22 = "✅";
          let pola31 = pragmaticPola[Math.floor(Math.random() * 8)];
          let pola32 = "";
          pola31 === "✅" ? pola32 = "❌" : pola32 = "✅";
          let value1 = `${betForPola[Math.floor(Math.random() * 8)]} ${pola11} ${pola12} ${pragmaticPola[Math.floor(Math.random() * 8)]}`;
          let value2 = `${betForPola[Math.floor(Math.random() * 8)]} ${pola21} ${pola22} ${pragmaticPola[Math.floor(Math.random() * 8)]}`;
          let value3 = `${betForPola[Math.floor(Math.random() * 8)]} ${pola31} ${pola32} ${pragmaticPola[Math.floor(Math.random() * 8)]}`;
          if (!data[i]) {
            mainAxios(
              {
                method: 'POST',
                url: '/postNewPragmaticPola',
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3
                }
              }
            )
              .then(() => {
                console.log('New Pola added!');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else if (now.getDate() + now.getHours() !== (new Date(data[i].updatedAt).getDate()) + (new Date(data[i].updatedAt).getHours())) {
            mainAxios(
              {
                method: 'PUT',
                url: '/updatePragmaticPola/' + data[i].id,
                data: {
                  value1: value1,
                  value2: value2,
                  value3: value3,
                  updatedAt: now.toISOString()
                }
              }
            )
              .then(() => {
                console.log('Pole baru saja diupdate');
              })
              .catch(err => {
                dispatch(setGameIsError(err));
              })
          } else {
            console.log('Pola is up to date');
          }
        }
        mainAxios(
          {
            method: 'GET',
            url: '/getPragmaticPolas'
          }
        )
          .then(({ data }) => {
            dispatch(setPragmaticPolas(data));
          })
      })
      .catch(err => {
        dispatch(setGameIsError(err));
      })
      .finally(() => {
        dispatch(setGameIsLoading(false));
      });
  };
};