import { combineReducers } from 'redux';
import rtpReducer from './rtpReducer';
import ppGameReducer from './ppGameReducer';
import pgsGameReducer from './pgsGameReducer';
import hbGameReducer from './hbGameReducer';
import jgGameReducer from './jgGameReducer';
import sgGameReducer from './sgGameReducer';
import jiliGameReducer from './jiliGameReducer';
import fsGameReducer from './fsGameReducer';
import psGameReducer from './psGameReducer';
import l22GameReducer from './l22GameReducer';
import aisGameReducer from './aisGameReducer';
import cq9GameReducer from './cq9GameReducer';
import mgGameReducer from './mgGameReducer';
import ttgGameReducer from './ttgGameReducer';

const reducers = combineReducers({
  rtpReducer,
  ppGameReducer,
  pgsGameReducer,
  hbGameReducer,
  jgGameReducer,
  sgGameReducer,
  jiliGameReducer,
  fsGameReducer,
  psGameReducer,
  l22GameReducer,
  aisGameReducer,
  cq9GameReducer,
  mgGameReducer,
  ttgGameReducer
});

export default reducers;