import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCQ9GamesData, fetchCQ9PolasData } from '../../store/actions/cq9GameAction';
import Loading from '../Loading';

const CQ9Card = (data) => {
  const dispatch = useDispatch();
  const { cq9Names, cq9Images, cq9Polas, nameAndImagesIsLoading } = useSelector((state) => state.cq9GameReducer);

  useEffect(() => {
    dispatch(fetchCQ9PolasData());
    dispatch(fetchCQ9GamesData());
  }, [dispatch]);

  if (data.isLoading || nameAndImagesIsLoading) return (
    <>
      <Loading />
    </>
  )

  return (
    <>
      <h2>CQ9</h2>
      <section className="container px-3 sm:px-12 mx-auto" id="hb">
        <div className='grid grid-cols-2 lg:grid-cols-6 gap-4 justify-items-center'>
          {
            cq9Names.map((cq9Name, i) => {
              return (
                <div key={i} className="w-full grid justify-items-center bg-item p-3">
                  <div className="relative flex-shrink-0">
                    <img width={"150px"} src={cq9Images[i]} alt={cq9Name} />
                  </div>
                  <div className="grid box-content text-center content-center capitalize mb-3">
                    <span className="game-name h-12 grid content-center">{cq9Name}</span>
                    <div className="jam px-5 py-3 mb-3">
                      <span>Jam Gacor: </span>
                      <span className="jamGacor">
                        {data.jamGacors[i] ? data.jamGacors[i].value7 : ''}
                      </span>
                    </div>
                    <span>Pola: </span>
                    <span className="v">{cq9Polas[i] ? `${cq9Polas[i].value1} - ${cq9Polas[i].value2} - ${cq9Polas[i].value3}` : ''}</span>
                  </div>
                  <div className='single-bar relative items-center'>
                    <div className='absolute font-bold rtpValue' style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                      <span>{data.rtps[i] ? data.rtps[i].value7 : ''}%</span>
                    </div>
                    <div className="single-bar__fill bar animated-gradient" style={{ width: `${data.rtps[i] ? data.rtps[i].value7 : ''}%` }}></div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </section >
    </>
  );
};

export default CQ9Card;