import {
  SET_RTP,
  SET_GAME_JAM,
  SET_RTP_ISLOADING,
  SET_RTP_ISERROR
} from '../actionTypes';
import { mainAxios } from '../../apis/axios';

const now = new Date();
const times = ["01:00 - 02:55", "02:00 - 04:05", "05:00 - 07:35", "08:00 - 10:15", "11:00 - 13:25", "14:00 - 16:10", "15:00 - 17:30", "16:00 - 18:21", "17:05 - 19:10", "18:05 - 20:18", "19:15 - 21:40", "20:35 - 21:55", "21:40 - 22:45", "22:00 - 23:55", "23:00 - 00:05", "00:35 - 01:55", "01:15 - 02:30", "02:10 - 04:30", "05:20 - 07:30", "09:30 - 11:20", "12:25 - 14:40", "15:40 - 17:40", "19:00 - 21:30", "23:20 - 01:45", "03:15 - 05:30", "07:10 - 09:20", "11:15 - 13:55", "15:05 - 17:10", "19:25 - 21:30", "22:05 - 00:15", "01:45 - 03:25", "05:10 - 06:55", "08:45 - 10:25", "11:25 - 13:15", "14:45 - 16:15", "17:55 - 19:35", "20:45 - 22:05", "23:15 - 01:10", "02:25 - 04:50", "06:30 - 08:15", "09:45 - 11:25", "12:55 - 14:50", "15:25 - 17:15", "18:45 - 20:30", "21:40 - 23:05", "00:25 - 02:40", "03:40 - 05:45", "06:35 - 08:40", "10:50 - 13:15", "14:25 - 17:40", "19:30 - 21:15", "22:20 - 00:15", "01:03 - 02:33", "02:08 - 04:35", "05:18 - 07:15", "08:09 - 10:19", "11:07 - 13:15", "14:09 - 16:17", "15:06 - 17:36", "16:07 - 18:24", "17:08 - 19:47", "18:02 - 20:38", "19:18 - 21:43", "20:34 - 21:58", "21:46 - 22:13", "22:01 - 23:59", "23:03 - 00:28", "00:39 - 01:59", "01:17 - 02:39", "02:13 - 04:37", "05:28 - 07:33", "09:34 - 11:27", "12:23 - 14:48", "15:41 - 17:49", "19:04 - 21:37", "23:23 - 01:47", "03:13 - 05:36", "07:17 - 09:29", "11:16 - 13:53", "15:13 - 17:19", "19:26 - 21:38", "22:45 - 00:13", "01:51 - 03:53", "05:52 - 06:15", "08:38 - 10:27", "11:26 - 13:31", "14:31 - 16:18", "17:37 - 19:39", "20:43 - 22:52", "23:43 - 01:31", "02:26 - 04:47", "06:38 - 08:17", "09:47 - 11:29", "12:57 - 14:43", "15:28 - 17:18", "18:43 - 20:38", "21:41 - 23:37", "00:21 - 02:47", "03:57 - 05:42", "06:38 - 08:43", "10:53 - 13:18", "14:27 - 17:48", "19:37 - 21:16", "22:21 - 00:16"];

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const setRTP = (payload) => {
  return {
    type: SET_RTP,
    payload: payload
  };
};

export const setJam = (payload) => {
  return {
    type: SET_GAME_JAM,
    payload: payload
  };
};

export const setRTPIsLoading = (payload) => {
  return {
    type: SET_RTP_ISLOADING,
    payload: payload
  };
};

export const setRTPIsError = (payload) => {
  return {
    type: SET_RTP_ISERROR,
    payload: payload
  };
};

export const fetchRTPsData = () => {
  return async (dispatch) => {
    let newData = [];
    dispatch(setRTPIsLoading(true));
    await mainAxios(
      {
        method: 'GET',
        url: '/getWinRate'
      }
    )
      .then(({ data }) => {
        newData = data;
        for (let i = 0; i < 2060; i++) {
          if (!data[i]) {
            mainAxios(
              {
                method: 'POST',
                url: '/postWinRate',
                data: {
                  value1: getRandomInt(60, 97),
                  value2: getRandomInt(60, 97),
                  value3: getRandomInt(60, 97),
                  value4: getRandomInt(60, 97),
                  value5: getRandomInt(60, 97),
                  value6: getRandomInt(60, 97),
                  value7: getRandomInt(60, 97),
                  value8: getRandomInt(60, 97),
                  value9: getRandomInt(60, 97),
                  value10: getRandomInt(60, 97),
                  value11: getRandomInt(60, 97),
                  value12: getRandomInt(60, 97),
                  value13: getRandomInt(60, 97),
                  value14: getRandomInt(60, 97),
                  value15: getRandomInt(60, 97),
                  value16: getRandomInt(60, 97),
                  value17: getRandomInt(60, 97),
                  value18: getRandomInt(60, 97),
                  value19: getRandomInt(60, 97),
                  value20: getRandomInt(60, 97)
                }
              }
            )
              .then(() => {
                console.log('New RTP is added');
              })
              .catch(err => {
                dispatch(setRTPIsError(err));
              })
          } else if (now.getDate() + now.getHours() !== (new Date(data[i].updatedAt).getDate()) + (new Date(data[i].updatedAt).getHours())) {
            let values = {
              value1: getRandomInt(60, 97),
              value2: getRandomInt(60, 97),
              value3: getRandomInt(60, 97),
              value4: getRandomInt(60, 97),
              value5: getRandomInt(60, 97),
              value6: getRandomInt(60, 97),
              value7: getRandomInt(60, 97),
              value8: getRandomInt(60, 97),
              value9: getRandomInt(60, 97),
              value10: getRandomInt(60, 97),
              value11: getRandomInt(60, 97),
              value12: getRandomInt(60, 97),
              value13: getRandomInt(60, 97),
              value14: getRandomInt(60, 97),
              value15: getRandomInt(60, 97),
              value16: getRandomInt(60, 97),
              value17: getRandomInt(60, 97),
              value18: getRandomInt(60, 97),
              value19: getRandomInt(60, 97),
              value20: getRandomInt(60, 97),
              updatedAt: now
            }
            mainAxios(
              {
                method: 'PUT',
                url: '/updateWinRate/' + data[i].id,
                data: values
              }
            )
              .then(() => {
                newData[i] = values;
                console.log('RTP just updated');
              })
              .catch(err => {
                console.log(err);
              })
          } else {
            console.log('RTP is up to date');
          }
        }
        dispatch(setRTP(newData));
      })
      .catch(err => {
        dispatch(setRTPIsError(err));
      })
      .finally(() => {
        dispatch(setRTPIsLoading(false));
      });
  };
};

export const fetchJamGacorsData = () => {
  return async (dispatch) => {
    let newData = [];
    dispatch(setRTPIsLoading(true));
    await mainAxios(
      {
        method: 'GET',
        url: '/getJamGacors'
      }
    )
      .then(({ data }) => {
        newData = data;
        for (let i = 0; i < 1500; i++) {
          let newJamGacor = Math.floor(Math.random() * 103);
          if (!data[i]) {
            let newTime = times[newJamGacor];
            while (Number(now.getHours()) >= Number(newTime[8] + newTime[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime = times[anNewJamGacor]
            }
            mainAxios(
              {
                method: 'POST',
                url: '/postNewJamGacor',
                data: {
                  value1: times[Math.floor(Math.random() * 103)],
                  value2: times[Math.floor(Math.random() * 103)],
                  value3: times[Math.floor(Math.random() * 103)],
                  value4: times[Math.floor(Math.random() * 103)],
                  value5: times[Math.floor(Math.random() * 103)],
                  value6: times[Math.floor(Math.random() * 103)],
                  value7: times[Math.floor(Math.random() * 103)],
                  value8: times[Math.floor(Math.random() * 103)],
                  value9: times[Math.floor(Math.random() * 103)],
                  value10: times[Math.floor(Math.random() * 103)],
                  value11: times[Math.floor(Math.random() * 103)],
                  value12: times[Math.floor(Math.random() * 103)],
                  value13: times[Math.floor(Math.random() * 103)],
                  value14: times[Math.floor(Math.random() * 103)],
                  value15: times[Math.floor(Math.random() * 103)],
                  value16: times[Math.floor(Math.random() * 103)],
                  value17: times[Math.floor(Math.random() * 103)],
                  value18: times[Math.floor(Math.random() * 103)],
                  value19: times[Math.floor(Math.random() * 103)],
                  value20: times[Math.floor(Math.random() * 103)]
                }
              }
            )
              .then(() => {
                console.log('New Gacor Time is added');
              })
              .catch(err => {
                dispatch(setRTPIsError(err));
              })
          } else if (now.getDate() !== new Date(data[i].updatedAt).getDate()) {
            let newTimeAfterChangeDay1 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay2 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay3 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay4 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay5 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay6 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay7 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay8 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay9 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay10 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay11 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay12 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay13 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay14 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay15 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay16 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay17 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay18 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay19 = times[Math.floor(Math.random() * 103)];
            let newTimeAfterChangeDay20 = times[Math.floor(Math.random() * 103)];
            mainAxios(
              {
                method: 'PUT',
                url: '/updateJamGacor/' + data[i].id,
                data: {
                  value1: newTimeAfterChangeDay1,
                  value2: newTimeAfterChangeDay2,
                  value3: newTimeAfterChangeDay3,
                  value4: newTimeAfterChangeDay4,
                  value5: newTimeAfterChangeDay5,
                  value6: newTimeAfterChangeDay6,
                  value7: newTimeAfterChangeDay7,
                  value8: newTimeAfterChangeDay8,
                  value9: newTimeAfterChangeDay9,
                  value10: newTimeAfterChangeDay10,
                  value11: newTimeAfterChangeDay11,
                  value12: newTimeAfterChangeDay12,
                  value13: newTimeAfterChangeDay13,
                  value14: newTimeAfterChangeDay14,
                  value15: newTimeAfterChangeDay15,
                  value16: newTimeAfterChangeDay16,
                  value17: newTimeAfterChangeDay17,
                  value18: newTimeAfterChangeDay18,
                  value19: newTimeAfterChangeDay19,
                  value20: newTimeAfterChangeDay20,
                }
              }
            )
              .then(() => {
                newData[i].value1 = newTimeAfterChangeDay1;
                newData[i].value2 = newTimeAfterChangeDay2;
                newData[i].value3 = newTimeAfterChangeDay3;
                newData[i].value4 = newTimeAfterChangeDay4;
                newData[i].value5 = newTimeAfterChangeDay5;
                newData[i].value6 = newTimeAfterChangeDay6;
                newData[i].value7 = newTimeAfterChangeDay7;
                newData[i].value8 = newTimeAfterChangeDay8;
                newData[i].value9 = newTimeAfterChangeDay9;
                newData[i].value10 = newTimeAfterChangeDay10;
                newData[i].value11 = newTimeAfterChangeDay11;
                newData[i].value12 = newTimeAfterChangeDay12;
                newData[i].value13 = newTimeAfterChangeDay13;
                newData[i].value14 = newTimeAfterChangeDay14;
                newData[i].value15 = newTimeAfterChangeDay15;
                newData[i].value16 = newTimeAfterChangeDay16;
                newData[i].value17 = newTimeAfterChangeDay17;
                newData[i].value18 = newTimeAfterChangeDay18;
                newData[i].value19 = newTimeAfterChangeDay19;
                newData[i].value20 = newTimeAfterChangeDay20;
                console.log('Jam Gacor just updated');
              })
              .catch(err => {
                dispatch(setRTPIsError(err));
              })
          } else if (Number(now.getHours()) >= Number(data[i].value1[8] + Number(data[i].value1[9])) ||
            Number(now.getHours()) >= Number(data[i].value2[8] + Number(data[i].value2[9])) ||
            Number(now.getHours()) >= Number(data[i].value3[8] + Number(data[i].value3[9])) ||
            Number(now.getHours()) >= Number(data[i].value4[8] + Number(data[i].value4[9])) ||
            Number(now.getHours()) >= Number(data[i].value5[8] + Number(data[i].value5[9])) ||
            Number(now.getHours()) >= Number(data[i].value6[8] + Number(data[i].value6[9])) ||
            Number(now.getHours()) >= Number(data[i].value7[8] + Number(data[i].value7[9])) ||
            Number(now.getHours()) >= Number(data[i].value8[8] + Number(data[i].value8[9])) ||
            Number(now.getHours()) >= Number(data[i].value9[8] + Number(data[i].value9[9])) ||
            Number(now.getHours()) >= Number(data[i].value10[8] + Number(data[i].value10[9])) ||
            Number(now.getHours()) >= Number(data[i].value11[8] + Number(data[i].value11[9])) ||
            Number(now.getHours()) >= Number(data[i].value12[8] + Number(data[i].value12[9])) ||
            Number(now.getHours()) >= Number(data[i].value13[8] + Number(data[i].value13[9])) ||
            Number(now.getHours()) >= Number(data[i].value14[8] + Number(data[i].value14[9])) ||
            Number(now.getHours()) >= Number(data[i].value15[8] + Number(data[i].value15[9])) ||
            Number(now.getHours()) >= Number(data[i].value16[8] + Number(data[i].value16[9])) ||
            Number(now.getHours()) >= Number(data[i].value17[8] + Number(data[i].value17[9])) ||
            Number(now.getHours()) >= Number(data[i].value18[8] + Number(data[i].value18[9])) ||
            Number(now.getHours()) >= Number(data[i].value19[8] + Number(data[i].value19[9])) ||
            Number(now.getHours()) >= Number(data[i].value20[8] + Number(data[i].value20[9]))
          ) {
            let newTime1 = data[i].value1;
            let newTime2 = data[i].value2;
            let newTime3 = data[i].value3;
            let newTime4 = data[i].value4;
            let newTime5 = data[i].value5;
            let newTime6 = data[i].value6;
            let newTime7 = data[i].value7;
            let newTime8 = data[i].value8;
            let newTime9 = data[i].value9;
            let newTime10 = data[i].value10;
            let newTime11 = data[i].value11;
            let newTime12 = data[i].value12;
            let newTime13 = data[i].value13;
            let newTime14 = data[i].value14;
            let newTime15 = data[i].value15;
            let newTime16 = data[i].value16;
            let newTime17 = data[i].value17;
            let newTime18 = data[i].value18;
            let newTime19 = data[i].value19;
            let newTime20 = data[i].value20;
            while (Number(now.getHours()) >= Number(newTime1[8] + newTime1[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime1 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime2[8] + newTime2[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime2 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime3[8] + newTime3[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime3 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime4[8] + newTime4[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime4 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime5[8] + newTime5[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime5 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime6[8] + newTime6[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime6 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime7[8] + newTime7[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime7 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime8[8] + newTime8[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime8 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime9[8] + newTime9[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime9 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime10[8] + newTime10[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime10 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime11[8] + newTime11[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime11 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime12[8] + newTime12[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime12 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime13[8] + newTime13[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime13 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime14[8] + newTime14[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime14 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime15[8] + newTime15[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime15 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime16[8] + newTime16[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime16 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime17[8] + newTime17[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime17 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime18[8] + newTime18[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime18 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime19[8] + newTime19[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime19 = times[anNewJamGacor]
            }
            while (Number(now.getHours()) >= Number(newTime20[8] + newTime20[9])) {
              let anNewJamGacor = Math.floor(Math.random() * 103);
              newTime20 = times[anNewJamGacor]
            }
            mainAxios(
              {
                method: 'PUT',
                url: '/updateJamGacor/' + data[i].id,
                data: {
                  value1: newTime1,
                  value2: newTime2,
                  value3: newTime3,
                  value4: newTime4,
                  value5: newTime5,
                  value6: newTime6,
                  value7: newTime7,
                  value8: newTime8,
                  value9: newTime9,
                  value10: newTime10,
                  value11: newTime11,
                  value12: newTime12,
                  value13: newTime13,
                  value14: newTime14,
                  value15: newTime15,
                  value16: newTime16,
                  value17: newTime17,
                  value18: newTime18,
                  value19: newTime19,
                  value20: newTime20,
                }
              }
            )
              .then(() => {
                newData[i].value1 = newTime1;
                newData[i].value2 = newTime2;
                newData[i].value3 = newTime3;
                newData[i].value4 = newTime4;
                newData[i].value5 = newTime5;
                newData[i].value6 = newTime6;
                newData[i].value7 = newTime7;
                newData[i].value8 = newTime8;
                newData[i].value9 = newTime9;
                newData[i].value10 = newTime10;
                newData[i].value11 = newTime11;
                newData[i].value12 = newTime12;
                newData[i].value13 = newTime13;
                newData[i].value14 = newTime14;
                newData[i].value15 = newTime15;
                newData[i].value16 = newTime16;
                newData[i].value17 = newTime17;
                newData[i].value18 = newTime18;
                newData[i].value19 = newTime19;
                newData[i].value20 = newTime20;
                console.log('Jam Gacor just updated');
              })
              .catch(err => {
                dispatch(setRTPIsError(err));
              })
          } else {
            console.log('Jam Gacor is up to date');
          }
        }
        dispatch(setJam(newData));
      })
      .catch(err => {
        dispatch(setRTPIsError(err));
      })
      .finally(() => {
        dispatch(setRTPIsLoading(false));
      });
  };
};