import { useNavigate } from 'react-router-dom';
import { PragmaticPlayLogo, PgSoftLogo, HabaneroLogo, JokerGamingLogo, SpadeGamingLogo, JiliLogo, FastSpinLogo, PlayStarLogo, Live22Logo, AisGamingLogo, CQ9Logo, MicroGamingLogo, TopTrendGamingLogo } from '../assets/icons'

const Menu = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;

  const menuHandler = (path) => {
    switch (path) {
      case '/pragmatic':
        navigate(path, { replace: true });
        break;
      case '/pg-soft':
        navigate(path, { replace: true });
        break;
      case '/habanero':
        navigate(path, { replace: true });
        break;
      case '/joker-gaming':
        navigate(path, { replace: true });
        break;
      case '/spade-gaming':
        navigate(path, { replace: true });
        break;
      case '/jili-gaming':
        navigate(path, { replace: true });
        break;
      case '/fast-spin':
        navigate(path, { replace: true });
        break;
      case '/play-star':
        navigate(path, { replace: true });
        break;
      case '/live-22':
        navigate(path, { replace: true });
        break;
      case '/ais-gaming':
        navigate(path, { replace: true });
        break;
      case '/cq9':
        navigate(path, { replace: true });
        break;
      case '/micro-gaming':
        navigate(path, { replace: true });
        break;
      case '/top-trend-gaming':
        navigate(path, { replace: true });
        break;
      default:
        break;
    }
  }

  return (
    <div className="main-menu-outer-container max-w-3xl mx-auto my-3" id="main_menu_outer_container">
      <span className="glyphicon glyphicon-chevron-left left_trigger">&#8678;</span>
      <main>
        <span onClick={() => menuHandler('/pragmatic')} className={location === '/pragmatic' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="PRAGMATIC PLAY" src={PragmaticPlayLogo} />
          PRAGMATIC PLAY
        </span>
        <span onClick={() => menuHandler('/pg-soft')} className={location === '/pg-soft' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="PG SOFT" src={PgSoftLogo} />
          PG SOFT
        </span>
        <span onClick={() => menuHandler('/habanero')} className={location === '/habanero' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="HABANERO" src={HabaneroLogo} />
          HABANERO
        </span>
        <span onClick={() => menuHandler('/joker-gaming')} className={location === '/joker-gaming' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="JOKER GAMING" src={JokerGamingLogo} />
          JOKER GAMING
        </span>
        <span onClick={() => menuHandler('/spade-gaming')} className={location === '/spade-gaming' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="SPADE GAMING" src={SpadeGamingLogo} />
          SPADE GAMING
        </span>
        <span onClick={() => menuHandler('/jili-gaming')} className={location === '/jili-gaming' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="JILI" src={JiliLogo} />
          JILI
        </span>
        <span onClick={() => menuHandler('/fast-spin')} className={location === '/fast-spin' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="FAST SPIN" src={FastSpinLogo} />
          FAST SPIN
        </span>
        <span onClick={() => menuHandler('/play-star')} className={location === '/play-star' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="PLAY STAR" src={PlayStarLogo} />
          PLAY STAR
        </span>
        <span onClick={() => menuHandler('/live-22')} className={location === '/live-22' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="LIVE 22" src={Live22Logo} />
          LIVE 22
        </span>
        <span onClick={() => menuHandler('/ais-gaming')} className={location === '/ais-gaming' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="AIS GAMING" src={AisGamingLogo} />
          AIS GAMING
        </span>
        <span onClick={() => menuHandler('/cq9')} className={location === '/cq9' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="CQ9" src={CQ9Logo} />
          CQ9
        </span>
        <span onClick={() => menuHandler('/micro-gaming')} className={location === '/micro-gaming' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="MICRO GAMING" src={MicroGamingLogo} />
          MICRO GAMING
        </span>
        <span onClick={() => menuHandler('/top-trend-gaming')} className={location === '/top-trend-gaming' ? 'cursor-pointer clicked' : 'cursor-pointer'}>
          <img loading="lazy" alt="TOP TREND" src={TopTrendGamingLogo} />
          TOP TREND
        </span>
      </main>
      <span className="glyphicon glyphicon-chevron-right right_trigger">&#8680;</span>
    </div>
  );
};

export default Menu;