import {
  SET_JG_GAME_NAMES,
  SET_JG_GAME_IMAGES,
  SET_JG_GAME_POLA,
  SET_GAME_ISLOADING,
  SET_GAME_ISERROR
} from '../actionTypes';

const initialState = {
  jgNames: [],
  jgImages: [],
  jgPolas: [],
  nameAndImagesIsLoading: true,
  isError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JG_GAME_NAMES:
      return { ...state, jgNames: action.payload }
    case SET_JG_GAME_IMAGES:
      return { ...state, jgImages: action.payload };
    case SET_JG_GAME_POLA:
      return { ...state, jgPolas: action.payload };
    case SET_GAME_ISLOADING:
      return { ...state, nameAndImagesIsLoading: action.payload };
    case SET_GAME_ISERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  };
};

export default reducer;