import axios from 'axios';

axios.defaults.headers.post["Content-Type"] = "application/json";

const mainAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

export {
  mainAxios
};