import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchJILIGamesData, fetchJiliPolasData } from '../../store/actions/jiliGameAction';
import Loading from '../Loading';

const SpadeGamingCard = (data) => {
  const dispatch = useDispatch();
  const { jiliNames, jiliImages, jiliPolas, nameAndImagesIsLoading } = useSelector((state) => state.jiliGameReducer);

  useEffect(() => {
    dispatch(fetchJiliPolasData());
    dispatch(fetchJILIGamesData());
  }, [dispatch]);

  if (data.isLoading || nameAndImagesIsLoading) return (
    <>
      <Loading />
    </>
  )

  return (
    <>
      <h2>Jili</h2>
      <section className="container px-3 sm:px-12 mx-auto" id="jg">
        <div className='grid grid-cols-2 lg:grid-cols-6 gap-4 justify-items-center'>
          {
            jiliNames.map((jiliName, i) => {
              return (
                <div key={i} className="w-full grid justify-items-center bg-item p-3">
                  <div className="relative flex-shrink-0">
                    <img width={"150px"} src={jiliImages[i]} alt={jiliName} />
                  </div>
                  <div className="grid box-content text-center content-center capitalize mb-3">
                    <span className="game-name h-12 grid content-center">{jiliName}</span>
                    <div className="jam px-5 py-3 mb-3">
                      <span>Jam Gacor: </span>
                      <span className="jamGacor">
                        {data.jamGacors[i] ? data.jamGacors[i].value6 : ''}
                      </span>
                    </div>
                    <span>Pola: </span>
                    <span className="v">{jiliPolas[i] ? `${jiliPolas[i].value1} - ${jiliPolas[i].value2} - ${jiliPolas[i].value3}` : ''}</span>
                  </div>
                  <div className='single-bar relative items-center'>
                    <div className='absolute font-bold rtpValue' style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                      <span>{data.rtps[i] ? data.rtps[i].value6 : ''}%</span>
                    </div>
                    <div className="single-bar__fill bar animated-gradient" style={{ width: `${data.rtps[i] ? data.rtps[i].value6 : ''}%` }}></div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </section >
    </>
  );
};

export default SpadeGamingCard;