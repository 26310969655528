import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPsGamesData, fetchPsPolasData } from '../../store/actions/psGameAction';
import Loading from '../Loading';

const PlayStarCard = (data) => {
  const dispatch = useDispatch();
  const { psNames, psImages, psPolas, nameAndImagesIsLoading } = useSelector((state) => state.psGameReducer);
  
  useEffect(() => {
    dispatch(fetchPsPolasData());
    dispatch(fetchPsGamesData());
  }, [dispatch]);

  if (data.isLoading || nameAndImagesIsLoading) return (
    <>
      <Loading />
    </>
  )

  return (
    <>
      <h2>Play Star</h2>
      <section className="container px-3 sm:px-12 mx-auto" id="ps">
        <div className='grid grid-cols-2 lg:grid-cols-6 gap-4 justify-items-center'>
          {
            psNames.map((psName, i) => {
              return (
                <div key={i} className="w-full grid justify-items-center bg-item p-3">
                  <div className="relative flex-shrink-0">
                    <img width={"150px"} src={psImages[i]} alt={psName} />
                  </div>
                  <div className="grid box-content text-center content-center capitalize mb-3">
                    <span className="game-name h-12 grid content-center">{psName}</span>
                    <div className="jam px-5 py-3 mb-3">
                      <span>Jam Gacor: </span>
                      <span className="jamGacor">
                        {data.jamGacors[i] ? data.jamGacors[i].value11 : ''}
                      </span>
                    </div>
                    <span>Pola: </span>
                    <span className="v">{psPolas[i] ? `${psPolas[i].value1} - ${psPolas[i].value2} - ${psPolas[i].value3}` : ''}</span>
                  </div>
                  <div className='single-bar relative items-center'>
                    <div className='absolute font-bold rtpValue' style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                      <span>{data.rtps[i] ? data.rtps[i].value11 : ''}%</span>
                    </div>
                    <div className="single-bar__fill bar animated-gradient" style={{ width: `${data.rtps[i] ? data.rtps[i].value11 : ''}%` }}></div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </section >
    </>
  );
};

export default PlayStarCard;