const Loading = () => {
  return (
    <div className="flex">
      <div className="w-full flex items-center justify-center h-screen">
        <div className="w-40 h-40 border-t-4 border-b-4 border-indigo-900 rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

export default Loading;