import {
  SET_L22_GAME_NAMES,
  SET_L22_GAME_IMAGES,
  SET_L22_GAME_POLA,
  SET_GAME_ISLOADING,
  SET_GAME_ISERROR
} from '../actionTypes';

const initialState = {
  l22Names: [],
  l22Images: [],
  l22Polas: [],
  nameAndImagesIsLoading: true,
  isError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_L22_GAME_NAMES:
      return { ...state, l22Names: action.payload }
    case SET_L22_GAME_IMAGES:
      return { ...state, l22Images: action.payload };
    case SET_L22_GAME_POLA:
      return { ...state, l22Polas: action.payload };
    case SET_GAME_ISLOADING:
      return { ...state, nameAndImagesIsLoading: action.payload };
    case SET_GAME_ISERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  };
};

export default reducer;