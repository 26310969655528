export const PragmaticPlayLogo = require('./pragmatic-play.png');
export const PgSoftLogo = require('./pgsoft.png');
export const HabaneroLogo = require('./habanero.png');
export const JokerGamingLogo = require('./joker-gaming.png');
export const SpadeGamingLogo = require('./spade-gaming.png');
export const JiliLogo = require('./jili.png');
export const FastSpinLogo = require('./fast-spin.png');
export const PlayStarLogo = require('./play-star.png');
export const Live22Logo = require('./live22.png');
export const AisGamingLogo = require('./ais-gaming.png');
export const CQ9Logo = require('./cq9.png');
export const MicroGamingLogo = require('./micro-gaming.png');
export const TopTrendGamingLogo = require('./top-trend-gaming.png');