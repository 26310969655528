import {
  SET_RTP,
  SET_GAME_JAM,
  SET_RTP_ISLOADING,
  SET_RTP_ISERROR
} from '../actionTypes';

const initialState = {
  rtps: [],
  jamGacors: [],
  isLoading: true,
  isError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RTP:
      return { ...state, rtps: action.payload };
    case SET_GAME_JAM:
      return { ...state, jamGacors: action.payload };
    case SET_RTP_ISLOADING:
      return { ...state, isLoading: action.payload };
    case SET_RTP_ISERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  };
};

export default reducer;