import Logo from "../components/logos/LogoP4D";
import Button from "../components/buttons/ButtonP4D";
import Menu from "../components/Menu";

const NotFound = () => {
  return (
    <>
      <Logo />
      <Button />
      <Menu />
      <div id="main">
        <div className="fof">
          <h1>Error 404</h1>
        </div>
      </div>
    </>
  );
};

export default NotFound;